import React, {useState, useEffect} from 'react';
import {Table, Container, Form, Toast, Button, Row, Col} from 'react-bootstrap'
import Layout from "../components/layout"
import SEO from "../components/seo";
import Loader from '../components/loading';
import GenericPagination from '../components/genericPagination';
import AddSection from '../components/design/addSection';
import Fetch from '../helpers/fetch';
import constants from '../helpers/constants';

const DesignSectionOptionsSummary = () => {
  const emptyEditItem = {
    id: '',
    sectionDesc: '',
    itemType:'',
    sequence:'',
    showInDesignSheet: true,
    showTextInDesignSheet: false,
    forFrontview: true,
    forBackview: false,
  }
  const [options, setOptions] = useState([]);
  const [sections, setSections] = useState([]);
  const [editItem, setEditItem] = useState({});

  const [showLoader, setShowLoader] = useState(false);
  const [pagination, setPagination] = useState({});
  const [rowLimit, setRowLimit] = useState(25);
  const [page, setPage] = useState(1);


  const [itemType, setItemType] = useState('');
  const [sectionId, setSectionId] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [showEditOption, setShowEditOption] = useState(false);
  const [showAddOption, setShowAddOption] = useState(false);
  const [showSummaryTable, setShowSummaryTable] = useState(true);


  const [showToast, setShowToast] = useState(false);
  const [errorMessage, seterrorMessage] = useState('')

  const getSections = () => {
    let queryString = '';
    let queryParams = [];
    let url = `${constants.BACKEND_URL}/design-sections/all`
    if (page) {
      queryParams.push(`page=${page}`)
    }
    if (itemType) {
      queryParams.push(`itemType=${itemType}`)
    }
    if(queryParams.length > 0) {
      queryString = `?${queryParams.join('&')}`
    }
    Fetch.get(`${url}${queryString}`).then(data => {
      setSections(data.data)
      setPagination(data.pagination);
    });
  }

  const getSectionsByItemType = (itemType) => {
    const url=`${constants.BACKEND_URL}/design-sections/${itemType}`
    Fetch.get(url).then(data => {
     setSections(data.sections)
    })
  }

  const getSection = (sectionId) => {
    let obj = {}
    if (sections) {
      obj = sections.find(section => {
        return Number(section.id) === Number(sectionId)
      })
      
    }
    return obj;
  }
  
  useEffect(() => {
    getSections()
    return () => {
      
    }
  }, [itemType, page])

  const handleOnItemTypeChange = (e) => {
    setItemType(e.target.value)
    setSectionId('');
    getSectionsByItemType(e.target.value);
  }

  const handleOnEditSectionClick = (e) => {
    const obj = JSON.parse(e.target.dataset.value);
    setEditItem(obj)
    setIsEditing(true);
    setShowAddOption(true);
    setShowSummaryTable(false);
  }

  const handleOnAddSectionClick = (e) => {
    setIsEditing(false);
    emptyEditItem.itemType = itemType;
    setEditItem(emptyEditItem);
    setShowAddOption(true);
    setShowSummaryTable(false);
  }

  const handleItemChange = (e) => {
    const changedProperty = {}
    const {property} = e.target.dataset;
    let value = e.target.value;
    
    if (property === 'showInDesignSheet' || property === 'showTextInDesignSheet' || property === 'forFrontview' || property === 'forBackview')  {
      if (e.target.checked) {
        value = true;
      } else {
        value = false;
      }
    }
    changedProperty[property] = value;
    setEditItem({...editItem, ...changedProperty});
  }

  const saveEditItem = (e) => {
    setShowLoader(true)
    const url = `${constants.BACKEND_URL}/design-sections`
    if(!isEditing) {
      Fetch.post(url, JSON.stringify(editItem))
      .then((res) => {
        if(res.error) {
          setShowLoader(false);
          setShowToast(true);
          seterrorMessage(res.error);
          return;
        }
        if (res.data) {
          setOptions(res.data)
          setShowSummaryTable(true);
          setShowAddOption(false);
          
        }
        getSections();
        
      })
    } else {
      Fetch.put(`${url}/${editItem.id}`, JSON.stringify(editItem))
      .then((res) => {
        if(res.error) {
          setShowLoader(false);
          setShowToast(true);
          seterrorMessage(res.error);
          return;
        }
        if(!res.status) {
          setShowToast(true);
          seterrorMessage(res.msg);
          return;
        }
        getSections();
      })
    }
    setIsEditing(false);
    setShowAddOption(false);
    setShowSummaryTable(true);
    setShowLoader(false);
  }

  const cancelEditItem = (e) => {
    setShowSummaryTable(true);
    setShowAddOption(false);
    setShowEditOption(false);
  }

  const handleOnDeleteSectionClick = (e) => {
    const deleteId = e.target.dataset.deleteId;
    const url=`${constants.BACKEND_URL}/design-sections/${deleteId}`
    setShowLoader(true);
    Fetch.delete(url).then(data => {
      if (data && data.error) {
        setShowLoader(false);
        setShowToast(true);
        seterrorMessage(data.error);
        return;
      }
      getSections();
      setShowLoader(false);
    })
  }

  const handleOnPaginationClick = (e) => {
    let target = e.target.dataset.goTo
    if (!target) {
      target = e.target.parentNode.dataset.goTo;
    }
    switch (target) {
      case "firstPage":
        setPage(1);
        break;
      case "prevPage":
        setPage(page-1);
        break;
      case "nextPage":
        setPage(page+1);
        break;
      case "lastPage":
        setPage(pagination.totalPages);
        break;
      default:
        setPage(target);
        break;
    }
  }
  

  return (
    <Layout pageInfo={{ pageName: "designSectionSummary" }}>
      <Loader show={showLoader}></Loader>
      <SEO title="Design Section" />
      <Container className="text-center" >
        <Row>
          <Col className="text-center">
            <h5>Design Section Summary</h5>
          </Col>
        </Row>
        { showSummaryTable && 
          <>
            <Row>
              <Col>
                <Form.Group as={Row}>
                  <Form.Label column sm={3}>Item Type</Form.Label>
                  <Col>
                    <Form.Control as="select" 
                      value={itemType}
                      onChange={handleOnItemTypeChange}
                    >
                      <option value=''>--Select Status--</option>
                      {
                        constants.ITEM_TYPES.map(x => {
                          return (
                            <option key={x} >{x}</option>
                          )
                        })
                      }
                    </Form.Control>
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-3">
              {
                itemType && 
                <Col lg={2} md="4" xs="6" className="text-left">
                  <Button onClick={handleOnAddSectionClick}>Add Section</Button>
                </Col>
              }
            </Row>
            <Row>
              <Col>
                <Table responsive>
                  <thead>
                    <tr>
                      <th>Item Type</th>
                      <th>Section</th>
                      <th>Sequence</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {sections && Array.isArray(sections) ? 
                      sections.map(item => {
                        return (
                          <tr key={item.id}>
                            <td>{item.itemType}</td>
                            <td>{item.sectionDesc}</td>
                            <td>{item.sequence}</td>
                            <td>
                              <Button className="mr-2" data-value={JSON.stringify(item)} onClick={handleOnEditSectionClick}>Edit</Button>
                              <Button variant="danger" data-delete-id={item.id} onClick={handleOnDeleteSectionClick}>Delete</Button>
                            </td>
                          </tr>
                        )
                      }) : ''
                    }
                  </tbody>
                </Table>  
              </Col>
            </Row>
          </>
        }
        <AddSection 
          show={showAddOption} 
          item={editItem} 
          handleItemChange={handleItemChange}
          saveEditItem={saveEditItem}
          cancelEditItem={cancelEditItem}
        ></AddSection>
        <Toast 
          show={showToast} 
          onClose={() => {setShowToast(false)}}
          autohide
          style={{
            position: 'absolute',
            top: '1vh',
            'minHeight': '2vh',
            right: 0,
          }}
        >
          
            <Toast.Body><span className="oi oi-warning text-danger"></span><strong className="mr-auto text-danger">{errorMessage}</strong></Toast.Body>
        </Toast>
        {pagination && showSummaryTable ? 
            <GenericPagination 
              pagination={pagination} 
              rowLimit={rowLimit}
              handleOnPaginationClick={handleOnPaginationClick}
            ></GenericPagination> : <></>}
        {/* <EditOption 
          show={showEditOption} 
          item={editItem} 
          handleItemChange={handleItemChange}
          saveEditItem={saveEditItem}
          cancelEditItem={cancelEditItem}
        ></EditOption>
        

        <StandardModal 
          onConfirm={handleModalConfirm}
          onClose={handleModalClose} 
          show={showDeleteModal}
          title="Delete Option"
          body={`Are you sure you want to delete Option?`}
        >
        </StandardModal>
        
        <RightSidebar 
        show={showRightSidebar}
        closeFn={handleOnSidebarClose}
        state={editItem}
        >
          <h5 className="text-center mb-2">Additional Info</h5>  
          <Table>
            <thead>
              <tr>
                <th>Label</th>
                <th>Value</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Name</td>
                <td>{editItem.name}</td>
              </tr>
              <tr>
                <td>Phone</td>
                <td>{editItem.phone}</td>
              </tr>
              <tr>
                <td>Email</td>
                <td>{editItem.email}</td>
              </tr>
            </tbody>
          </Table>
        </RightSidebar> */}
        </Container>
    </Layout>
  )
}

export default DesignSectionOptionsSummary
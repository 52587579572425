import React, { useState, useEffect } from 'react';
import {Form, Col, Row, Container, Button} from 'react-bootstrap'

const AddSection = ({show, showAddForm, item, handleItemChange, saveEditItem, cancelEditItem, isDisabled, showMeasurements}) => {
  const [validated, setValidated] = useState(false);
  
  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
      setValidated(true);
      return;
    }
    setValidated(false);
    saveEditItem();
  };

  const cancelAdd =(e) => {
    setValidated(false);
    cancelEditItem();
  }

  return (
    <>
    { 
      show &&
      <Container>
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          {!showAddForm &&
            <Form.Group as={Row} >
              <Form.Label column sm={2}>
                ID
              </Form.Label>
              <Col sm={10}>
                <Form.Control 
                  data-property="id" 
                  type="text" 
                  placeholder="" 
                  value={item['id']} 
                  readOnly={true}
                  />
              </Col>
            </Form.Group>
          }
          <Form.Group as={Row} >
            <Form.Label column sm={2}>
              Item Type
            </Form.Label>
            <Col sm={10}>
              <Form.Control 
                data-property={'itemType'}
                type="text"
                placeholder="" 
                value={item['itemType']} 
                readOnly={true}
              >
                  
              </Form.Control>
              <Form.Control.Feedback type="invalid">Please enter a value</Form.Control.Feedback>
            </Col>
          </Form.Group>
          <Form.Group as={Row} >
            <Form.Label column sm={2}>
              Section Name
            </Form.Label>
            <Col sm={10}>
              <Form.Control 
                data-property={'sectionDesc'}
                type="text"
                placeholder=""
                value={item['sectionDesc']} 
                onChange={handleItemChange}
              >
                  
              </Form.Control>
              <Form.Control.Feedback type="invalid">Please enter a value</Form.Control.Feedback>
            </Col>
          </Form.Group>
          <Form.Group as={Row} >
            <Form.Label column sm={2}>Show In Design Form</Form.Label>
            <Col sm={10}>
              <Form.Check
                style={{position:'absolute', top: '0.5rem'}}
                className="text-left" 
                type="switch"
                id="custom-switch"
                label={item['showInDesignSheet'] ? 'Yes' : 'No'}
                data-property="showInDesignSheet" 
                checked={item['showInDesignSheet']}
                onChange={handleItemChange}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} >
            <Form.Label column sm={2}>Show As Text</Form.Label>
            <Col sm={10}>
              <Form.Check
                style={{position:'absolute', top: '0.5rem'}}
                className="text-left" 
                type="switch"
                id="custom-text-switch"
                label={item['showTextInDesignSheet'] ? 'Yes' : 'No'}
                data-property="showTextInDesignSheet" 
                checked={item['showTextInDesignSheet']}
                onChange={handleItemChange}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} >
            <Form.Label column sm={2}>For Frontview in Order Sheet</Form.Label>
            <Col sm={10}>
              <Form.Check
                style={{position:'absolute', top: '0.5rem'}}
                className="text-left" 
                type="switch"
                id="forFrontview-switch"
                label={item['forFrontview'] ? 'Yes' : 'No'}
                data-property="forFrontview" 
                checked={item['forFrontview']}
                onChange={handleItemChange}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} >
            <Form.Label column sm={2}>For Backview in Order Sheet</Form.Label>
            <Col sm={10}>
              <Form.Check
                style={{position:'absolute', top: '0.5rem'}}
                className="text-left" 
                type="switch"
                id="forBackview-switch"
                label={item['forBackview'] ? 'Yes' : 'No'}
                data-property="forBackview" 
                checked={item['forBackview']}
                onChange={handleItemChange}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} >
            <Form.Label column sm={2}>
              Sequence
            </Form.Label>
            <Col sm={10}>
              <Form.Control 
                data-property={'sequence'}
                type="text"
                placeholder=""
                value={item['sequence']} 
                onChange={handleItemChange}
              >
                  
              </Form.Control>
              <Form.Control.Feedback type="invalid">Please enter a value</Form.Control.Feedback>
            </Col>
          </Form.Group>
          <Button className="mr-1 mb-3" type="submit" disabled={isDisabled}>Save</Button>
          {cancelEditItem && <Button className="mr-1 mb-3" variant="danger" onClick={cancelAdd}>Cancel</Button>}
        </Form>
      </Container>
    }
    </>
  )
}

export default AddSection